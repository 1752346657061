<template>
  <div class="amap-container">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '地图',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <div class="amap" ref="amap" id="amap"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { getClockRule } from "@/api/clock/index.js";
import { getSign } from "@/api/wechat";
import isWeixin from "@/utils/isWeixin.js";
import { wgs84ToGcj02 } from "@/utils/wgs84ToGcj02.js";
import TopWrapper from "@/components/topWrapper/index.vue";

const amapKey = "f0ad268ea5ce7e3210db733326750359";

export default {
  name: "clockIn",
  props: {},
  components: {TopWrapper },
  data() {
    return {
      amap: null,
      clockRule: {},
      coordinate: {},
      AMap: null,
    };
  },
  watch: {},
  async mounted() {
    await this.getRule();
    await this.initMap();
    this.getConfig();
    this.getAndroidConfig();
  },
  filters: {},
  computed: {},
  methods: {
    async initMap() {
      await AMapLoader.load({
        key: amapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        animateEnable: false,
        plugins: ["AMap.PlaceSearch", "AMap.Geolocation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.AMap = AMap;
        this.amap = new AMap.Map("amap", {
          zoom: 16, //级别
          zooms: [2, 20],
          center: [this.clockRule.gcj02Lon, this.clockRule.gcj02Lat],
          animateEnable: true, // 地图移动时的动画
          showLabel: true,
        });
        this.amap.on("complete", () => {
          this.addCenterMarker();
          this.addCircle();
        });
      });
    },
    addCenterMarker() {
      let marker = new AMap.Marker({
        icon: new AMap.Icon({
          image:
            "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          size: new AMap.Size(24, 30),
          imageSize: new AMap.Size(24, 30),
        }),
        position: [this.clockRule.gcj02Lon, this.clockRule.gcj02Lat],
        offset: new AMap.Pixel(-12, -30),
      });
      marker.setLabel({
        direction: "top",
        offset: new AMap.Pixel(-4, -10), //设置文本标注偏移量
        content: `<p>${this.clockRule.address}</p><div class='down'><div>`, //设置文本标注内容
      });
      this.amap.add(marker);
    },

    addCircle() {
      // 创建圆形 Circle实例
      let circle = new AMap.Circle({
        center: [this.clockRule.gcj02Lon, this.clockRule.gcj02Lat], //圆心
        radius: this.clockRule.clockRang, //半径
        borderWeight: 1,
        fillOpacity: 0.2,
        fillColor: "#1791fc",
        zIndex: 50,
      });

      this.amap.add(circle);
      this.amap.setFitView([circle]);
    },

    addPersonMarker() {
      let marker = new this.AMap.CircleMarker({
        center: [this.coordinate.longitude, this.coordinate.latitude],
        radius: 5, //3D视图下，CircleMarker半径不要超过64px
        strokeColor: "white",
        fillColor: "rgba(0,0,255,1)",
        zIndex: 10,
      });
      this.amap.add(marker);
    },
    async getRule() {
      await getClockRule().then((res) => {
        this.clockRule = res.data;
      });
    },

    getConfig() {
      //自定义分享所需要的参数
      if (!isWeixin()) {
        return;
      }
      // 进行签名的时候  Android 不用使用之前的链接， ios 需要
      let signLink = /(Android)/i.test(navigator.userAgent)
        ? location.href.split("#")[0]
        : window.entryUrl;
      getSign({
        url: signLink,
      }).then((res) => {
        wx.config({
          debug: false, //测试时候用true 能看见wx.config的状态是否是config:ok
          appId: process.env.VUE_APP_WXAPPID, // 必填，公众号的唯一标识（公众号的APPid）
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "openLocation", // 使用微信内置地图查看位置接口
          ], // 必填，需要使用的JS接口列表
        });
        wx.error((res) => {
          // console.error("error", res);
        });
        wx.checkJsApi({
          jsApiList: ["openLocation"], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
          success: (res) => {
            this.getClockInfo();
          },
        });
      });
    },
    getClockInfo() {
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: (res) => {
          this.coordinate = res;
          // console.log(this.coordinate);
          this.addPersonMarker();
        },
        fail: (e) => {
          // console.error(e);
        },
      });
    },

    getAndroidConfig() {
      // 判断Android-app登录
      if (!plus) {
        return;
      }
      this.getAndroidClockInfo();
    },
    // android获取打卡信息
    getAndroidClockInfo() {
      plus.geolocation.getCurrentPosition(
        (p) => {
          let gcj = wgs84ToGcj02(p.coords.latitude, p.coords.longitude);

          // p为获取成功的定位数据
          this.coordinate = {
            latitude: gcj.latitude,
            longitude: gcj.longitude,
          };
          this.addPersonMarker();
        },
        (err) => {
          //失败回调
          console.error(
            "Gelocation Error: code - " +
              err.code +
              "; message - " +
              err.message
          );
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.amap-container {
  height: 100%;
  .amap {
    width: 100%;
    height: 100%;
  }
}
::v-deep .amap-marker-label {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  padding: 0.1rem 0.3rem;
  border-radius: 0.08rem;
  text-align: center;
  // position: relative;
  .down {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 0.08rem solid rgba(0, 0, 0, 0.7);
    border-bottom: 0.08rem solid rgba(0, 0, 0, 0.7);
    border-top: 0.08rem solid transparent;
    border-right: 0.08rem solid transparent;
    transform: rotate(315deg);
    position: absolute;
    bottom: -0.07rem;
  }
}
</style>
